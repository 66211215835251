<template>
  <div> 
    <div class="panel-body row">
      
      <div class="col-md-12 " style="margin-bottom:5px;">
        <div>
          <h4>Conversion Rate</h4>
        </div>
        
      </div>
      <div class="col-md-6">
        <div style="padding: 10px; display:block; ">
            <dashbord-cards
              v-if="showChartConvertion"
              :charttype="cards[0].charttype"
              :chartdata="cards[0].chartdata"
              :type="cards[0].type"
              :cardBg="cards[0].cardBg"
              :name="cards[0].name"
              :value="cards[0].value"
              :icon="cards[0].icon"
              :styleChart="'auto'"
              @setDuration="getTotalCountConvertion"
            />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showChartConvertion">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      <div class="col-md-6">
        <div style="padding: 10px; display:block; ">
          <dashbord-cards
            v-if="showBestSelling"
            :charttype="cards[1].charttype"
            :chartdata="chart_bestsales.chartdata"
            :value="'Best Selling Produts'"
            :icon="cards[1].icon"
            :styleChart="'auto'"
            :cardBg="cards[0].cardBg"
            :update="updatepie"
            :optionsPie="chart_bestsales.chartoptions"
            :component="'piechart'"
            @setDuration="getBestSellingConversion"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showBestSelling">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      <div class="col-md-12">
        <b-card no-body>
          <b-card-body class="p-1 content-tb">
            <div class="panel panel-default p-1 ">
              <div style="padding: 10px;">
                <h4>Low Sale Products</h4>
                <div class="panel-body row">
                  <div class="col-sm-9" style="padding-left:0px;">
                    <div class="input-group col-sm-3" style="padding-left:15px;">
                      <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Product" value="" name="voucher">          
                    </div>
                  </div>
                  <div class="col-sm-3" style="padding-right:15px;">
                  </div>
                </div> 
                <table id="example" class="table table-striped table-border-out" >
                  <thead>
                    <tr>
                      <th scope="col" class="head-table">Product ID</th>
                      <th scope="col" class="head-table">Product Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tableItems" :key="item.product_id">
                      <td>{{ item.product_id }}</td>
                      <td>{{ item.product_name }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="tableItems.length == 0" class="row text-center">
                  <B>No products</B>
                </div>
                <div class="row text-right">
                  <div class="col-sm-12">
                    <div class="mt-3">
                      <b-pagination 
                      v-model="currentPage" 
                      :per-page="pageSize"
                      :total-rows="rowcount" 
                      align="right"></b-pagination>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
          </b-card>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showBadSelling && showChartConvertion">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      </div>
    </div>
</template>
<script>
import DashbordCards from '@/components/common/Dashbord/DashbordCards';
import mixinDashboard from "@/mixins/merchant/APIDashboard";
export default {
  mixins: [mixinDashboard],
   components: { 
     DashbordCards
   },
  data: function () {
    return {
      breadcrumb: [
          {
              text: "Dashboard",
              href: "/merchant-dashbord",
              active: false
          },
          {
              text: "Visitors",
              active: true
          }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      updatepie: 1,
      showChartConvertion: true,
      showBadSelling: true,
      showBestSelling: true,
      selected_option: 1,
      chart_bestsales: {
        chartdata: {
          labels: [],
          datasets: [
            {
              backgroundColor: [
                '#41B883',
                '#E46651',
                '#00D8FF',
                '#DD1B16'
              ],
              data: []
            }
          ]
        },
        chartstyle: {
          height: `auto`,
          width: `auto`
        },
        chartoptions: {
          responsive: true,
          maintainAspectRatio: false, 
          legend: {
              display: false
          },
        }
      },
      tableItems:[],
      storeListData:[],
      cards:[
        {
          cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
          name: "Total Conversion Rate",
          value: "",
          icon: "fa fa-star",
          type: "single-view",
          charttype: 'DynamicChartLine',
          chartdata: {
            labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            datasets: [
              {
                label: '',
                backgroundColor: '#9de19f',
                borderColor: "#9de19f",
                data: [],
                fill: false
              }, 
            ]
          }
         },
        {
          cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
          name: " ",
          value: "Best Selling Produts ",
          icon: "fa fa-truck",
          type: "card-chart",
          charttype: 'DynamicChartDougnut',
          chartdata: {
            labels: [],
            datasets: [
              {
                label: 'Card',
                backgroundColor: '#d49de1',
                borderColor: "#ffcd05",
                data: [],
                fill: false
              }, 
            ]
          }
        }
      ]
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchProduct(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchProduct(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getTotalCountConvertion(1);
    this.getBestSellingConversion(1);
    this.getBadSellingConversion(1);
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);
    }, 
    searchProduct: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.product_id &&
                  obj.product_id.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.product_name &&
                  obj.product_name.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.storeListData.length;
      }
      
    },
    getTotalCountConvertion: async function(id) {
        try {
          this.showChartConvertion = false;
          let response = await this.getTotalCountConvertionApi(id);
          if (response) {
            this.cards[0].value = response.count + "";
            response.chart_data.reverse();
            this.cards[0].chartdata.labels = [];
            this.cards[0].chartdata.datasets.data = [];
            response.chart_data.forEach(element => {
              this.cards[0].chartdata.labels.push(
                id==1?"Day"+element._id:id==2? "Week"+element._id: id== 3 ? "Month"+element._id : ""
              );
              this.cards[0].chartdata.datasets[0].data.push( element.total_count);
            });
          }
          this.showChartConvertion = true;
        } catch (error) {
          this.showChartConvertion = true;
          throw(error);
        }
    },
    getBestSellingConversion: async function(id) {
      try {
        this.showBestSelling = false;
        let response = await this.getBestSellingConversionApi(id);
        if(response){
          response.chart_data.reverse();
          let dataX = [];
          this.chart_bestsales.chartdata.labels= [];
          this.chart_bestsales.chartdata.datasets[0].data=[];
          response.chart_data.forEach(element => {
            this.chart_bestsales.chartdata.labels.push(element.product_name);
            this.chart_bestsales.chartdata.datasets[0].data.push(element.total_count);
          });
          
        }
        this.showBestSelling = true;
      } catch (error) {
        this.showBestSelling = true;
        throw(error); 
      }
    },
    getBadSellingConversion: async function(id) {
      try {
        let response = await this.getBadSellingConversionApi(id);
        if(response){
          // this.tableItems = response.chart_data;
          this.storeListData = response.chart_data;
        } else {
          this.storeListData = [];
        }
        this.searchProduct("");
      } catch (error) {
        throw(error); 
      }
    }
  }
}
</script>
<style >
.content-tb {
    background-color: #ffffff !important;
    color: #6a6a6a !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    border: white;
}
#chart-bar-0 {
    height: 550px!important;
}
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
    border: 1px solid #ddd;
}
</style>